import React, { useState, useEffect, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { AuthContext } from "src/context/Auth";
const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",

    "&:hover": {
      color: "#ffff",
      background: theme.palette.background.active,

      borderRadius: "0px",
      "& $icon": {
        color: "#ffff",
      },
    },
  },
  buttonLeaf: {
    color: theme.palette.primary.main,
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "5px",
    borderRadius: 0,
    opacity: 1,

    fontWeight: "400",
    fontSize: "13px",
    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      transition: "all 0.5s ease",
      background: "radial-gradient(circle at 30%, #FFC002 10%, transparent 90%) ",
      borderRadius: "10px",
      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 600,
        fontSize: "14px",
        whiteSpace: "pre",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#000",
    fontSize: "12px",
    fontWeight: 600,
    transition: "all 1s ease-in-out",
    borderRadius: "10px",
    background: "radial-gradient(circle at 10%,#FFC002,transparent) !important ",

    "& $icon": {
      color: "#000",
    },
  },
  activeTitle: {
    color: "#000",
    fontSize: "12px",
    fontWeight: 600,
    transition: "all 1s ease-in-out",
    borderRadius: "10px",
    background: "radial-gradient(circle at 10%,#FFC002,transparent) !important ",

    "& $icon": {
      color: "#000",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: IconSrc,
  info: Info,
  open: openProp,
  title,
  tabview,
  setSelectedTab,
  ...rest
}) => {

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (tabview === "Sniper") {
      setSelectedTab(tabview)
    }
  },[tabview,setSelectedTab])



  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };


  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle}>
          {IconSrc && <img src={IconSrc} alt="" className={classes.icon} height={25} width={25} />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {" "}
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth},
          ${auth.pageTitle === title && classes.activeTitle}
          `)}
          component={RouterLink}
          // exact
          to={href}
        >
          {IconSrc && <img src={IconSrc} alt="" className={classes.icon} height={25} width={25} />}
          <span className={classes.title}>{title}</span>
          {Info && <Info />}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  linkdata: PropTypes.string
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
