import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },


  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashboardManagement/Dashboard")),
  },
  {
    exact: true,
    path: "/user-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/UserList")),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/UserManagement/ViewUser")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/self-care-category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Self Care/Category/index")),
  },
  {
    exact: true,
    path: "/add-subcategory",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Self Care/SubCategory/AddSubCategory")),
  },
  {
    exact: true,
    path: "/edit-self-care",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Self Care/Category/editSelfCare")),
  },
  {
    exact: true,
    path: "/edit-sub-category",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Self Care/SubCategory/editSubCategory")),
  },
  {
    exact: true,
    path: "/view-subcategory",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Self Care/SubCategory/viewSubCategory")),
  },
  {
    exact: true,
    path: "/hip-hop-stories",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopStories")),
  },

  {
    exact: true,
    path: "/hip-hop-music",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopMusic")),
  },
  {
    exact: true,
    path: "/add-music",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopMusic/addMusic")),
  },
  {
    exact: true,
    path: "/edit-music",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopMusic/editMusic")),
  },
  {
    exact: true,
    path: "/view-music",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopMusic/viewMusic")),
  },
  {
    exact: true,
    path: "/self-assesment",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SelfAssesment/index")),
  },

  {
    exact: true,
    path: "/coaches",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Coaches/index")),
  },

  {
    exact: true,
    path: "/view-coach",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Coaches/viewCoach")),
  },
  {
    exact: true,
    path: "/add-stories",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopStories/AddStories")),
  },

  {
    exact: true,
    path: "/edit-story",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopStories/EditStory")),
  },
  {
    exact: true,
    path: "/add-hop-question",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/HipHopStories/AddQuestions")),
  },
  {
    exact: true,
    path: "/add-question",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SelfAssesment/AddQuestion")),
  },
  {
    exact: true,
    path: "/edit-question",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SelfAssesment/EditQuestion")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentManagement/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/view-privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentManagement/ViewPrivacyPolicy")),
  },
  {
    exact: true,
    path: "/edit-privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentManagement/EditPrivacyPollicy")),
  },
  {
    exact: true,
    path: "/thoughts",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentManagement/Thoughts")),
  },
  {
    exact: true,
    path: "/edit-thoughts",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContentManagement/EditThoughts")),
  },
  {
    exact: true,
    path: "/contact-Us",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContactUs/index")),
  },
  {
    exact: true,
    path: "/view-contact",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ContactUs/viewContact")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
