import React, {  useContext } from "react";
import { makeStyles} from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    overflow: "hidden",
    position: "relative",
    background:"url('images/darkbackground.png')",
    height: "100vh",
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1:{
    height: "calc(100vh - 75px)",
    position: "relative",
  }

}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
 <div className={classes.contentContainer1}>

      <div className={classes.MainLayout}>{children}</div>
 </div>
    </div>
  );
};

export default MainLayout;
