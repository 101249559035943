const url = "https://nodepune-hiphop.mobiloitte.io/api/v1";
// const url = "http://localhost:3058/api/v1"
// const url = "http://172.16.2.14:3058/api/v1";

// const url = "http://172.16.2.14:3058/api/v1";
const admin = `${url}/admin`;  
const category = `${url}/category`;
const story = `${url}/story`;
const question = `${url}/question`;
const coach = `${url}/coach`;
const stati = `${url}/static`;
const music = `${url}/music`;
const contact = `${url}/contactUs`;
const storyquestion = `${url}/storyQuestion`;
const thought = `${url}/user`;
const subcategory=`${url}/subcategory`

const ApiConfig = {
  adminLogin: `${admin}/login`,
  adminForgetPass: `${admin}/forgotPassword`,
  adminResetPass: `${admin}/resetPassword`,
  verifyOtp: `${admin}/verifyOTP`,
  resendOtp: `${admin}/resendOTP`,


  //Profile --->Profile
  adminGetProfile: `${admin}/getProfile`,

  //adminDashboard ---> Dashboard
  adminDashboard: `${admin}/adminDashboard`,

  //getUserList ---> User Management
  userManagement: `${admin}/getUserList`,
  viewUser: `${admin}/viewUser/`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  
  adminDeleteUser: `${admin}/adminRemoveUser`,

  //Category --->  SelfCare
  createSelfCare: `${category}/createCategory`,
  getSelfCare: `${category}/listcategory`,
  deleteSelfCare: `${category}/deletecategory`,
  editSelfCare: `${category}/updateCategory`,
  blockUnblockCategory: `${admin}/blockUnblockCategory`,
  getSubCategory:`${subcategory}/listsubcategory`,
 
  createSubCategory:`${subcategory}/createsubcategory`,
  editSubCategory:`${subcategory}/updatesubcategory`,
  deleteSubCategory:`${subcategory}/deletesubcategory`,
  // viewSubCategory:`${subcategory}/getsubCategoryList`,
  viewSubCategory:`${url}/subcategory/getsubCategoryList`, //made change here

  //story ---> HipHopStory
  getHopStories: `${story}/getStoryList`,
  deleteStories: `${story}/removeStory`,
  addStory: `${story}/addStory`,
  addStoryQuestion: `${url}/storyquestion/addStoryQuestion`,
  getStoryQuestions: `${story}/getStoryQuestions`,
  editStory: `${story}/editStory`,
  getStoryFolderList:`${story}/getStoryfolderList`,
  
  //Journal Question ---> Self-Assement-question
  getJournalQuestion: `${question}/getjournalQuestion`,
  deleteJournalQuestion: `${question}/removeQuestion`,
  addJournalQuestion: `${question}/addQuestion`,
  editJournalQuestion: `${question}/editQuestion`,

  //Coach_Details ---> Coach-Management
  getCoachDetails: `${coach}/getCoachList`,
  viewCoach: `${coach}/viewCoachDetails`,
  deleteCoach: `${admin}/adminDeleteCoach`,
  coachblockUnblock: `${admin}/blockUnblockCoach`,
  approveRejectCoach: `${coach}/aprrovedOrRejectCoach`,

  //Music-Education ----> Music Education
  getMusicList: `${music}/getMusicList`,
  getAlbumList:`${music}/getAlbumList`,
  deleteMusicList:`${music}/adminDeleteFolderSong`,
  viewSong:`${music}/viewSong`,
  addMusic:`${music}/adminAddFolderSong`,
  editMusic:`${music}/admineditFolderSong`,
  //ChangePassword  
  changePassword: `${admin}/changePassword`,

  //Static-content ---> ContentManagement
  getPrivacyPolicy: `${stati}/static`,
  addStaticContent: `${stati}/addStaticContent`,
  static: `${stati}/staticContentList`,
  editPrivacyPolicy:`${stati}/editStaticContent`,

  //ContactUs Management--->contactUs
  getContactUsList: `${contact}/getContactUsList`,
  viewContactUs: `${contact}/viewContactUs`,
  deleteContactUs: `${contact}/deleteContactUs`,
  replyContactUs: `${contact}/replyContactUs`,


  //Thoughts--Thought
  getThoughts: `${thought}/getCoat`,
  updateThoughts: `${admin}/adminupdateCoat`,

};
export default ApiConfig;
