import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getDataHandlerWithToken } from "src/apiconfig/service";
import apiconfig from "src/apiconfig/apiconfig";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.localStorage.getItem("token")
    ? window.localStorage.getItem("token")
    : token;
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("");
  const history = useHistory();
  const handleTitle = (values) => {
    setPageTitle(values);
  };
  const getProfileData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: apiconfig.adminGetProfile,
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      if (res.data?.responseCode === 200) {
        setUserData(res?.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (
        error.response?.data?.responseMessage ==
        "Session Expired, Please login again."
      ) {
        history.push("/");
        toast.error("Session Expired, Please login again.");
      } else if (error.response?.data?.responseMessage) {
        toast.error(error.response?.data?.responseMessage);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin === true) {
      getProfileData();
    }
  }, []);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    isLoading,
    timeLeft,
    pageTitle,
    handleTitle: (values) => {
      handleTitle(values);
    },
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    setIsLogin,
    getProfileData: () => {
      getProfileData();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
